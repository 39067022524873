<template>
  <div v-if="loaded">
    <div class="container h-100 content">
      <div class="d-flex justify-content-center h-100">
        <div class="login block link-block">
          <div>
            <h3>Bill Payment</h3>

            <div v-if="isError" class="alert-box" :class="error.class">
              <div class="title">
                {{ error.title }}
              </div>

              <p>
                {{ error.desc }}
              </p>
            </div>

            <div v-else>
              <p>
                Below are the details of your current bill(s) for
                <strong>&pound;{{ paymentData.total }}</strong
                >. Please verify the details below and pay what is required.
              </p>

              <h4>Bill Details</h4>
              <div
                class="bills-block"
                v-bind:key="invoice.invoice"
                v-for="invoice in activeInvoices"
              >
                <div class="invoice">{{ invoice.invoice }}</div>
                <div class="price">&pound;{{ invoice.total }}</div>
              </div>

              <h4>Paid to Date</h4>
              <div v-if="paymentComplete" class="paid-block is-paid">
                &pound;{{ paidAmount }}
              </div>
              <div
                v-else
                class="paid-block"
                v-bind:class="{ 'is-paid': paymentData.paid > 0 }"
              >
                &pound;{{ paymentData.paid }}
              </div>

              <h4>Make Payment</h4>
              <p v-if="!paymentComplete">
                You can make full or partial payment below. If required you may
                also overpay to cover any other outstanding balances.
              </p>

              <div v-if="paymentComplete">
                <b-alert variant="success" show>
                  <strong>Payment Complete</strong>
                  <div>
                    We have received your payment. If you have an account you
                    will see this on your dashboard shortly.
                  </div>
                </b-alert>
              </div>

              <div v-if="stripeElementsSecret && !paymentComplete">
                <div
                  class="card-styles"
                  style="max-width: 600px; border: 1px solid #bbb; transition: box-shadow .2s ease,-webkit-box-shadow .2s ease; padding: 5px;"
                  id="card-element"
                ></div>

                <div class="payment-button">
                  <b-button
                    v-on:click="purchase"
                    variant="outline-success"
                    v-if="!paymentLoading"
                    >Pay £{{ requestAmount }}</b-button
                  >
                  <div v-if="paymentLoading">
                    <b-spinner variant="primary" label="Spinning"></b-spinner>
                  </div>
                </div>
              </div>

              <div v-if="!stripeElementsSecret && !paymentComplete">
                <div class="row" style="padding-top: 0px; padding-bottom: 0px;">
                  <div class="col-md-12" style="margin-top: 20px;">
                    <b-input-group size="lg" prepend="£" class="payment-input">
                      <b-form-input
                        placeholder="Amount"
                        v-model="requestAmount"
                        type="number"
                        step="0.01"
                        min="0.01"
                        max="500.00"
                      ></b-form-input>
                    </b-input-group>
                  </div>
                </div>

                <div class="payment-button">
                  <b-button
                    v-on:click="requestPayment"
                    v-if="!paymentLoading"
                    variant="outline-info"
                    >Continue</b-button
                  >
                  <div v-if="paymentLoading">
                    <b-spinner variant="primary" label="Spinning"></b-spinner>
                  </div>
                </div>

                <PaymentNotes></PaymentNotes>
              </div>

              <div class="footer-info">
                <p>
                  If you need any assistance please contact the customer
                  services team via our
                  <a href="https://dataenergy.co.uk/residents" target="_blank"
                    >Residents Hub</a
                  >
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { faSpinner, faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import PaymentNotes from "@/views/Shared/PaymentNotes";

library.add(fas, faSpinner, faAlignLeft);

export default {
  name: "CreditRequestToPay",
  components: { PaymentNotes },
  props: ["id"],
  mounted: function() {
    this.configureStripe();
  },
  data() {
    return {
      stripeAPIToken: process.env.VUE_APP_STRIPE_CREDIT_PUBLIC_KEY,
      stripe: "",
      elements: "",
      card: "",
      loaded: false,
      paymentData: {},
      isError: false,
      error: {
        title: "",
        desc: "",
        class: ""
      },
      paymentComplete: false,
      stripeElementsSecret: null,
      requestAmount: "",
      paymentLoading: false,
      minAmount: 1,
      maxAmount: 500
    };
  },
  computed: {
    env() {
      return process.env.VUE_APP_ENV;
    },
    paidAmount() {
      return parseFloat(+this.paymentData.paid + +this.requestAmount).toFixed(
        2
      );
    },
    activeInvoices() {
      let invoices = [];
      /*this.paymentData.invoices.forEach(function(row) {

      });*/
      for (const key in this.paymentData.invoices) {
        if (!this.paymentData.invoices[key].is_active) {
          continue;
        }
        invoices.push({
          invoice: key,
          total: this.paymentData.invoices[key].total
        });
      }

      return invoices;
    }
  },
  created() {
    this.$store.dispatch("loaderCancel");
    this.fetchData();
  },
  methods: {
    configureStripe() {
      this.stripe = window.Stripe(this.stripeAPIToken);

      let style = {
        base: {
          color: "#32325d",
          fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
          fontSmoothing: "antialiased",
          fontSize: "16px",
          "::placeholder": {
            color: "#aab7c4"
          }
        },
        invalid: {
          color: "#fa755a",
          iconColor: "#fa755a"
        }
      };

      this.elements = this.stripe.elements();
      this.card = this.elements.create("card", { style: style });
    },
    requestPayment() {
      this.paymentLoading = true;

      let amount = this.requestAmount;
      let errorReason = null;

      if (isNaN(amount)) {
        errorReason = "Please enter a valid payment amount";
      }

      if (
        !errorReason &&
        Math.floor(amount) !== amount &&
        amount.toString().split(".")[1] !== undefined &&
        amount.toString().split(".")[1].length > 2
      ) {
        errorReason = "Please enter a valid payment amount";
      }

      if (!errorReason && amount < this.minAmount) {
        errorReason = "The minimum payment is £1.00";
      }

      if (!errorReason && amount > this.maxAmount) {
        errorReason = "The maximum payment is £500.00";
      }

      if (errorReason) {
        const title = "Payment Error";
        const subtitle = errorReason;
        const samePage = true;
        this.$store.dispatch("pushError", { title, subtitle, samePage });
        this.paymentLoading = false;
        return;
      }

      this.requestAmount = amount = parseFloat(amount).toFixed(2);

      this.$store
        .dispatch("initiateCreditPayment", {
          reference: this.id,
          verifier: this.paymentData.verifier,
          amount: this.requestAmount
        })
        .then(response => {
          this.$gtag.event("payment", {
            event_category: "requestToPay",
            event_label: amount
          });
          this.stripeElementsSecret = response.ref;

          this.$nextTick(() => {
            this.card.mount("#card-element");
          });
        })
        .catch(err => {
          const title = "Payment Error";
          const subtitle = err;
          const samePage = true;
          this.$store.dispatch("pushError", { title, subtitle, samePage });
        })
        .finally(() => {
          this.paymentLoading = false;
        });
    },
    setError(title, desc, className) {
      this.error.title = title;
      this.error.desc = desc;
      this.error.class = className;
      this.isError = true;
    },
    clearError() {
      this.error.title = "";
      this.error.desc = "";
      this.error.class = "";
      this.isError = false;
    },
    setResponse(state) {
      if (state === "expired") {
        this.setError(
          "Expired Request",
          "This request has expired and is no longer valid",
          "warning"
        );
      } else if (state === "paid") {
        this.setError(
          "No further payment required",
          "There are no further payments to be made against this request",
          "success"
        );
      }
    },
    purchase() {
      this.paymentLoading = true;
      this.stripe
        .confirmCardPayment(this.stripeElementsSecret, {
          payment_method: {
            card: this.card
          }
        })
        .then(result => {
          if (result.error) {
            let gTagCategory = "paymentErrorBillingRequestCard";
            let eventLabel = result.error.message;
            let customerError = result.error.message;

            if (result.error.type !== "card_error") {
              gTagCategory = "paymentErrorBillingRequestApp";
              customerError =
                "We were unable to process this payment. Please contact us if this issue persists.";
            }

            if (result.error.type === "validation_error") {
              customerError = result.error.message;
            }

            if (
              result.error.payment_method &&
              result.error.payment_method.card.brand === "amex"
            ) {
              eventLabel = "amex";
              customerError =
                "We no longer accept American Express Payments. Please use an alternative payment method";
            }

            this.$gtag.event(result.error.type, {
              event_category: gTagCategory,
              event_label: eventLabel
            });
            // Show error to your customer (e.g., insufficient funds)
            const title = "Payment Error";
            const subtitle = customerError;
            const samePage = true;
            this.$store.dispatch("pushError", { title, subtitle, samePage });
          } else {
            // The payment has been processed!
            if (result.paymentIntent.status === "succeeded") {
              this.$gtag.event("payment", {
                event_category: "paymentSuccessBillingRequest"
              });
              this.card.clear();
              this.stripeElementsSecret = null;
              this.paymentComplete = true;
              const title = "Payment Processing";
              const subtitle = "Your payment will be processed shortly";
              const samePage = true;
              this.$store.dispatch("pushSuccess", {
                title,
                subtitle,
                samePage
              });
            }
          }
          this.paymentLoading = false;
        });
    },
    includeStripe(URL, callback) {
      let documentTag = document,
        tag = "script",
        object = documentTag.createElement(tag),
        scriptTag = documentTag.getElementsByTagName(tag)[0];
      object.src = "//" + URL;
      if (callback) {
        object.addEventListener(
          "load",
          function(e) {
            callback(null, e);
          },
          false
        );
      }
      scriptTag.parentNode.insertBefore(object, scriptTag);
    },
    clearCard() {
      this.card.clear();
    },
    fetchData() {
      this.clearError();
      const title = "Fetching Payment";
      const subtitle = "Getting payment details";
      this.$store.dispatch("loaderInitiate", { title, subtitle });

      const reference = this.id;

      this.$store
        .dispatch("getCreditPaymentRequest", { reference })
        .then(response => {
          if (response.state !== "active") {
            return this.setResponse(response.state);
          }

          this.paymentData = response;
        })
        .catch(() => {
          this.setError(
            "Invalid Request",
            "Invalid link for payment. If this problem persists please contact us",
            "warning"
          );
        })
        .finally(() => {
          this.loaded = true;
          this.$store.dispatch("loaderCancel");
        });
    }
  }
};
</script>

<style lang="scss" scoped>
h2 {
  font-size: 1.6rem !important;
  text-align: left !important;
  margin-bottom: 10px !important;
  padding-left: 10px;
  margin-top: 20px;
}

p {
  color: #777;
  font-size: 0.9rem;
}

.payment-button {
  text-align: right;
  padding-top: 15px;
}

.link-block {
  width: 650px;
  margin-top: 30px;
}

.alert-box {
  padding: 20px;
  color: white;
  margin-bottom: 15px;
  margin-top: 20px;

  .title {
    font-weight: bold;
    padding-bottom: 20px;
    font-size: 1.2rem;
  }

  p {
    color: white;
  }

  &.danger {
    background-color: #ff7b71;
  }

  &.warning {
    background-color: #ff9800;
  }

  &.success {
    background-color: #83c45d;
  }
}

.bills-block {
  .invoice {
    font-size: 0.9rem;
    color: #555;
  }
  .price {
    font-size: 1.2rem;
    font-weight: bold;
    padding-bottom: 10px;
  }
}

.paid-block {
  font-size: 1rem;
  color: #888;

  &.is-paid {
    font-size: 1.2rem;
    font-weight: bold;
    color: #444;
  }
}

h4 {
  margin-top: 15px;
}

.footer-info {
  margin-top: 40px;

  p {
    font-size: 0.8rem;
    color: #888;
    margin-bottom: 0px;
  }
}
</style>
